var generic = generic || {};
var site = site || {};

(function ($) {
  'use strict';

  var bp = {};
  var isMobileWidth = true;
  var animationTime = 100;

  Drupal.behaviors.siteHeaderWrapperV1 = {
    elems: {},
    active: false,
    $activeCategory: false,
    menuAnimationInterval: false,
    options: {
      enableHover: true,
      // Our buffer for when we mouseenter between overlay / menu
      // This is for a smoother user experience
      overlayHoverCloseDelay: 400
    },
    attach: function (context) {
      var self = this;
      var $module = $('.js-site-header--v1', context);

      if (!$module.length) {
        return;
      }

      self.elems.$header = $module;
      self.elems.$globalOverlay = $('.js-content-dim', context);
      self.elems.$menu = $('.js-nav-menu', self.elems.$header);
      self.elems.$hamburger = $('.js-nav-launch', self.elems.$header);
      self.elems.$close = $('.js-nav-close', self.elems.$header);
      self.elems.$categoryTrigger = $('.js-nav-category-trigger', self.elems.$header);
      self.elems.$linkTrigger = $('.js-nav-link-trigger', self.elems.$header).not(
        '.menu__content .js-nav-link-trigger'
      );
      self.elems.$search = $('.js-nav-search', self.elems.$header);
      self.elems.$searchTrigger = $('.js-nav-search-trigger', self.elems.$header);
      self.elems.$bannerClose = $('.js-nav-close-banner', self.elems.$header);
      self.elems.$smallStickyNav = $('.js-small-sticky-nav', self.elems.$header);
      self.elems.$headerElements = $('.js-header-banner, .js-header-main', self.elems.$header);
      self.elems.$stickyHeader = $('.js-sticky-nav', self.elems.$header);
      self.elems.$stickyHeaderMenu = $('.js-site-header-menu', self.elems.$header);
      self.elems.$stickyHeaderUtilityNav = $('.js-site-header-utility-nav', self.elems.$header);
      self.elems.$parentHeader = $('header');
      self.elems.$isStickyNav = self.elems.$header.hasClass('js-sticky-nav');
      self.elems.$staticHeader = $('.js-site-header-static', context);

      // Links that trigger dropdowns / overlays -- search, sign in dropdown
      self.elems.$contentWrapper = $('.js-nav-content-dropdown', self.elems.$header);
      self.elems.$contentTriggers = $('.js-nav-content', self.elems.$header);
      self.elems.$contentBlocks = $('.js-nav-content-block', self.elems.$header);
      self.elems.$searchClose = $('.esearch-nav__form-close', self.elems.$header);
      self.elems.$searchIcon = $('.site-header__utility--search', self.elems.$header);
      self.elems.$searchSubmit = $('.esearch-nav__form-submit', self.elems.$header);

      // Start listening
      self.updateResponsive();
      self.attachEventListeners();
      self.attachContentListeners();
      self.displayOffers();
      self.emailPopup();
    },

    // This function adjusts the variables on window resize
    updateResponsive: function () {
      var self = this;
      var newBreakpoint = Unison.fetch.now().name;

      // Close everything if we have a new breakpoint
      if (newBreakpoint !== bp) {
        bp = newBreakpoint;
        isMobileWidth = bp === 'small';
        self.elems.$header.trigger('gnav.close');
        self.closeContent();
      }
      // Determine what the top menu level is depending on screen size
      self.elems.$topMenuLevel = (function () {
        // On mobile detect when clicking outside the header
        if (isMobileWidth) {
          return self.elems.$menu.find('.site-header__menu-wrapper');
        } else {
          return self.elems.$header;
        }
      })();

      if (self.elems.$smallStickyNav.length > 0) {
        $('.js-site-content').addClass('sticky-site-content');
        $('.body_container.checkout').addClass('sticky-checkout-content');
        self.elems.$smallStickyNav.addClass('site-header__sticky');
      }

      if (self.elems.$isStickyNav) {
        self.elems.$parentHeader.addClass('sticky-header');
        // Search Box slide Up when clicking outside
        $(document).on('click', function (event) {
          var $menuSearch = self.elems.$header.find('.js-menu-search');
          var $slideSearch = self.elems.$header.find('.js-slide-search');
          var $slideSearchForm = $slideSearch.find('.js-nav-form');
          var $slideSearchTypeHead = $slideSearch.find('.js-typeahead-wrapper');

          if (
            $(event.target).closest($menuSearch).length === 0 &&
            $(event.target).closest($slideSearchForm).length === 0 &&
            $(event.target).closest($slideSearchTypeHead).length === 0 &&
            $slideSearch.is(':visible')
          ) {
            self.elems.$search.slideUp();
            self.elems.$header.removeClass('search-open');
          }
        });
      }

      // Stop listening to our scroll event
      $(window).off('scroll.gnav');

      // On any resize, redo this function
      $(window).one(
        'resize.gnav',
        _.debounce(function () {
          var windowHeight;

          // Refresh our variables
          self.updateResponsive();

          // Enable scrollbars for short screens.
          if (bp === 'small' && self.elems.$search.is(':visible')) {
            windowHeight = $(window).height();
            self.elems.$search.css({
              overflow: 'auto',
              maxHeight: windowHeight - 150
            });
          }
        }, 150)
      );
    },

    attachEventListeners: function () {
      var self = this;
      var $stickyScrollTime = 150;

      if (self.elems.$isStickyNav) {
        $stickyScrollTime = 10;
      }
      $(document).on(
        'scroll',
        _.debounce(function () {
          var changeClass =
            $(this).scrollTop() >=
            self.elems.$staticHeader.position().top + self.elems.$staticHeader.height();

          if (self.elems.$isStickyNav) {
            changeClass = $(this).scrollTop() > 0;
          }
          self.elems.$header.toggleClass('active', changeClass);
        }, $stickyScrollTime)
      );

      // Global Events
      self.elems.$header.off('gnav.open').on('gnav.open', function () {
        self.active = true;
        self.elems.$menu.addClass('active');
        self.closeContent();
        self.elems.$globalOverlay.addClass('active');
        if (isMobileWidth) {
          $('html, body').addClass('no-scroll');
        }
        // Avoid triggering clickoutside with the same click event that opens the gnav
        setTimeout(function () {
          // Only listen to clickoutside when menu is open
          self.elems.$topMenuLevel.one('clickoutside.menu', function () {
            self.elems.$header.trigger('gnav.close');
          });
        }, 200);
      });

      self.elems.$headerElements.hover(
        function () {
          self.elems.$header.addClass('hover');
        },
        function () {
          self.elems.$header.removeClass('hover');
        }
      );

      self.elems.$header.off('gnav.close').on('gnav.close', function () {
        self.active = false;
        // Close all active categories
        self.closeMenus();
        self.elems.$hamburger.removeClass('active');
        self.elems.$contentWrapper.stop(true, true).slideUp(animationTime);
        // Close the gnav itself
        self.elems.$menu.removeClass('active');
        self.elems.$contentTriggers.removeClass('active');
        self.elems.$contentBlocks.removeClass('active');
        self.elems.$topMenuLevel.off('clickoutside.menu');
        $('html,body').removeClass('no-scroll');
        self.elems.$globalOverlay.removeClass('active');
      });

      // Click Events
      self.elems.$searchIcon.off('click.searchIcon').on('click.searchIcon', function () {
        self.elems.$header.toggleClass('search-open');
        self.elems.$search.slideToggle();
        $(window).trigger('resize.gnav');
      });

      self.elems.$searchClose.off('click.searchClose').on('click.searchClose', function () {
        self.elems.$search.slideUp();
        self.elems.$header.removeClass('search-open');
        $(window).trigger('resize.gnav');
        if (site.client.isMobile) {
          var $mobileSection = $('.js-nav-mobile-section');
          var $mobileBanner = $('.js-header-banner');
          var $searchNav = $('.js-nav-mobile-section, .js-nav-search');

          $searchNav.addClass('hidden');
          $mobileSection.removeClass('hidden');
          $mobileBanner.removeClass('hidden');
        }
      });

      self.elems.$searchTrigger.off('click.searchTrigger').on('click.searchTrigger', function (e) {
        e.preventDefault();
        self.elems.$header.trigger('gnav.close');
        var $headerScrollTop = self.elems.$search.offset().top;

        if (self.elems.$isStickyNav) {
          $headerScrollTop = 0;
        }
        var $searchOverlay = $('.js-nav-search-overlay');
        if ($searchOverlay.length > 0) {
          var $popularContent = $('.js-end-typeahead-popular');
          var $searchInputElement = $('.js-end-search-term');
          $searchOverlay.removeClass('hidden');
          $searchInputElement.focus();
          $popularContent.removeClass('hidden');
          $(window).scrollTop($searchOverlay.offset().top);
        }

        $('html, body')
          .animate(
            {
              scrollTop: $headerScrollTop
            },
            self.options.animationTime
          )
          .promise()
          .done(function () {
            if (self.elems.$isStickyNav) {
              self.elems.$searchIcon.first().trigger('click');
              self.elems.$header.find('.js-end-search-term').focus();
            } else {
              self.elems.$search.find('.js-end-search-term').focus();
            }
          });
      });

      self.elems.$searchSubmit.off('click.searchSubmit').on('click.searchSubmit', function (e) {
        e.preventDefault();
        self.elems.$header.trigger('gnav.close');
        $(this).parents('form').submit();
      });

      self.elems.$bannerClose.off('click.bannerClose').on('click.bannerClose', function (e) {
        e.preventDefault();
        $(this).parent().slideUp(self.options.animationTime);
        self.elems.$menu.addClass('banner-closed');
      });

      self.elems.$hamburger.off('click.open-gnav').on('click.open-gnav', function (event) {
        event.preventDefault();
        $(this).toggleClass('active');
        if ($(this).hasClass('active')) {
          self.elems.$header.trigger('gnav.open');
        } else {
          self.elems.$header.trigger('gnav.close');
        }
      });

      self.elems.$linkTrigger
        .off('click.open-link mouseenter.open-link')
        .on('click.open-link mouseenter.open-link', function (event) {
          self.closeMenus();
        });

      self.elems.$categoryTrigger
        .off('click.open-category mouseenter.open-category')
        .on('click.open-category mouseenter.open-category', function (event) {
          event.preventDefault();
          // Do some validation to see if we should continue
          if (!self.checkHoverEventIsValid(event)) {
            return;
          }
          var $elem = $(this).siblings('.js-nav-category-trigger-content');

          // If we're opening a category on desktop, make sure any queued menu animation is cleared
          if (event.type === 'mouseenter') {
            // Set our closing animation
            clearTimeout(self.menuAnimationInterval);
          }
          // Break our closing animation
          // Open the category and pass this specific $categoryTrigger
          self.toggleCategory($(this));
        });

      // Header Menu hide when Mouse Leave event fire
      self.elems.$stickyHeaderMenu.on('mouseleave', function () {
        self.toggleCategory($(this));
      });

      self.elems.$globalOverlay
        .off('click.close-overlay mouseenter.close-overlay')
        .on('click.close-overlay mouseenter.close-overlay', function (event) {
          event.preventDefault();
          var closeOverlay = function () {
            self.elems.$header.trigger('gnav.close');
            self.closeContent();
            // Custom event for listeners
            self.elems.$globalOverlay.trigger('overlay.closed');
          };

          // Do some validation to see if we should continue
          if (!self.checkHoverEventIsValid(event)) {
            return;
          }
          // Mouseenter on desktop has a delayed animation, the click is right away
          if (event.type === 'mouseenter' && !isMobileWidth) {
            // We're going to close the overlay, but first if any of these elements are hovered over before the menu can close,
            // don't close the menu. A user might move their mouse to the overlay and then bring the mouse back to the menu.
            var $cancelOverlayCloseElems = $(
              '.js-nav-category-trigger-content, .js-nav-content-dropdown, .js-nav-content-block',
              self.elems.$header
            );

            $cancelOverlayCloseElems
              .off('mouseenter.cancel-close-overlay click.cancel-close-overlay')
              .one('mouseenter.cancel-close-overlay click.cancel-close-overlay', function (event) {
                clearTimeout(self.menuAnimationInterval);
              });
            // Set our closing animation
            self.menuAnimationInterval = setTimeout(
              closeOverlay,
              self.options.overlayHoverCloseDelay
            );
          } else {
            closeOverlay();
          }
        });
    },

    // Our dropdown content interaction
    attachContentListeners: function () {
      var self = this;

      // On click of a trigger in the header, show the content in the dropdown
      self.elems.$contentTriggers
        .off('click.content-trigger mouseenter.content-trigger')
        .on('click.content-trigger mouseenter.content-trigger', function (event) {
          // Check that this link doesn't actually go anywhere
          if ($(this).attr('href') === '#') {
            event.preventDefault();
          }
          // Do some validation to see if we should continue
          if (!self.checkHoverEventIsValid(event)) {
            return;
          }
          // On desktop mouseenter, if this trigger has data-hover="true", open it on hover
          // Otherwise do nothing on mouseenter for desktop
          if (event.type === 'mouseenter' && !isMobileWidth) {
            if ($(this).data('hover') !== true) {
              return;
              // If this is already active, and this is mouseenter on desktop only and this trigger has a hover interaction, prevent anything from happening
            } else if ($(this).hasClass('active')) {
              return;
            }
          }

          // If this is already open, close this
          if ($(this).hasClass('active')) {
            self.closeContent();

            return;
          }

          var contentSection = $(this).data('content');

          // Close the gnav
          self.elems.$header.trigger('gnav.close');
          // Get which content to load from this trigger
          // Hide all the blocks inside the content wrapper
          self.elems.$contentBlocks.addClass('hidden');
          self.elems.$contentBlocks
            .filter('[data-content="' + contentSection + '"]')
            .removeClass('hidden');
          // Show this one
          self.elems.$contentTriggers.removeClass('active');
          $(this).addClass('active');
          $(this)
            .parent()
            .find('.js-nav-content-dropdown')
            .stop(true, true)
            .slideDown(animationTime); // Use an increased animation time on mobile because of the gnav closing as well
          self.elems.$globalOverlay.addClass('active');
        });

      // Header Utility Nav hide when Mouse Leave event fire
      self.elems.$stickyHeaderUtilityNav.on('mouseleave', function (event) {
        if (!$(event.target).is('input, span')) {
          self.elems.$contentTriggers.removeClass('active');
          $('.js-nav-content-dropdown').hide();
        }
      });
    },

    // Check if the hover option is enabled and we're not on mobile
    checkHoverEventIsValid: function (event) {
      var self = this;

      if (event.type === 'mouseenter' && (!self.options.enableHover || isMobileWidth)) {
        return false;
      }
      // Check that its NOT a mouseenter event where enableHover is turned off or we're on mobile
      if ($(event.target).closest('.js-nav-content-block').length) {
        return false;
      }
      if (
        $(event.target).hasClass('js-nav-category-trigger') &&
        $(event.target).parent().hasClass('active')
      ) {
        return false;
      }

      return true;
    },

    // Close all of our content interaction
    closeContent: function () {
      var self = this;

      self.elems.$contentTriggers.removeClass('active');
      self.elems.$contentBlocks.removeClass('active');
      self.elems.$contentWrapper.stop(true, true).slideUp(animationTime);
      self.elems.$contentBlocks.addClass('hidden');
      self.elems.$globalOverlay.removeClass('active');
    },

    // This function slides down the category once a primary category is clicked
    toggleCategory: function ($category) {
      var self = this;
      // .js-nav-category-trigger-content is right next to category element
      var $elem = $category.siblings('.js-nav-category-trigger-content');
      // Determine if the element is already open
      var isOpen = !$elem.hasClass('nav-hidden');

      // If an existing element is open, close it and its parent
      // This will close the category that is open, including this category
      // However make sure that the clicked category is not a subcategory of the existing activeCategory
      if (self.$activeCategory !== false && !$.contains(self.$activeCategory[0], $category[0])) {
        self.$activeCategory.addClass('nav-hidden');

        // If the opening category is deeper than a top level category, only close the children within this
        if ($category.parents('li').length > 1) {
          self.$activeCategory.closest('li').removeClass('active');
          self.$activeCategory
            .closest('li')
            .find('.js-nav-category-trigger-content')
            .addClass('nav-hidden');
        } else {
          // When switching between two top level categories, just close everything
          self.closeMenus();
          self.elems.$globalOverlay.removeClass('active');
        }
        self.$activeCategory = false;
      }
      // If wasn't open already, open it now
      if (!isOpen) {
        self.$activeCategory = $elem;
        self.$activeCategory.removeClass('nav-hidden').parents('li').addClass('active');
        // The block above adds .hidden to .js-nav-category-trigger-content
        // If the menus are siblings, this causes the parent .js-nav-category-trigger-content to be hidden
        // This forces all parent .js-nav-category-trigger-content to not be hidden
        self.$activeCategory.parents('.js-nav-category-trigger-content').removeClass('nav-hidden');
        // On desktop, our first category click triggers the gnav to open
        if (!isMobileWidth) {
          self.elems.$header.trigger('gnav.open');
        }
      }
    },

    // This closes all menu items and hides their content including and subsections
    closeMenus: function () {
      var self = this;

      // Close everything
      self.elems.$menu.find('li').removeClass('active');
      // Find all parent li elements and hide their content
      self.elems.$menu.find('.js-nav-category-trigger-content').addClass('nav-hidden');
    },

    displayOffers: function () {
      var self = this;
      var $promos = $('.basic-responsive', self.elems.$header);

      if ($promos.length < 2) {
        return;
      }

      setInterval(function () {
        // Have to re-select .basic-responsive rather than use $promos because
        // we're changing the order.
        $('.basic-responsive', self.elems.$header)
          .first()
          .slideUp(function () {
            $(this).appendTo($(this).parent()).css({
              overflow: '',
              display: ''
            });
          });
      }, 3000);
    },

    // Email Sign-Up popup on first page load popup using cookie
    emailPopup: function () {
      var self = this;
      var $signupContainer = self.elems.$stickyHeaderUtilityNav.find(
        '.js-site-header__email-signup'
      );

      self.elems.$signupContent = $('.js-nav-content', $signupContainer);
      self.elems.$signupDropdown = $('.js-nav-content-dropdown', $signupContainer);
      self.elems.$signupDropdownContent = $('.js-nav-content-block', $signupContainer);
      var cookieName = 'email_signup';
      var hasCookie = $.cookie(cookieName);

      if ($signupContainer.length && !hasCookie) {
        self.elems.$staticHeader.addClass('hover');
        self.elems.$signupContent.addClass('active');
        self.elems.$signupDropdown.show();
        self.elems.$signupDropdownContent.removeClass('hidden');
        $.cookie(cookieName, '1');
      }
    }
  };

  $(document).on('user.loaded', function (e, d) {
    var $header = $('.js-site-header--v1');

    if (d.signed_in === 1) {
      $('.js-site-header__myaccount, .js-site-header__signout', $header).removeClass('hidden');
    } else {
      $('.js-site-header__signin', $header).removeClass('hidden');
    }
  });
})(jQuery);
